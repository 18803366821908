import { axios } from '@/utils/request'
export const versionAdd = (data) => axios({
  url: '/version/add',
  method: 'post',
  data
})
export const versionPage = (params) => axios({
  url: '/version/page',
  method: 'get',
  params
})
