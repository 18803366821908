<template>
  <nav-bar
    :title="title"
    left-text=""
    safe-area-inset-top
    :border="false"
    right-text=""
    :left-arrow="isArrow"
    @click-left="goback"
    :fixed="!isArrow"
    :class="isArrow ? 'fixed_bg' : 'arrow_bg'"
  />
</template>
<script>
import { NavBar } from 'vant'
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      title: '公文流转系统',
      isArrow: true,
    }
  },
  created() {
    this.isArrow = this.$route.name != 'mobileHome'
    // console.log(this.$route.name, '++++++++++++', this.$router)
  },
  methods: {
    goback() {
      console.log(11111111)
    },
  },
}
</script>
<style lang="less" scoped>
.arrow_bg {
  background: none;
  z-index: 999;
  padding: 5px 0;
  ::v-deep .van-nav-bar__title {
    color: #fff;
    font-size: 19px;
    font-weight: 400;
    letter-spacing: 1px;
  }
}
</style>