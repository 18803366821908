import router from '@/router';
import store from '@/store';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import { message, Modal, notification } from 'ant-design-vue'; /// es/notification
import axios from 'axios';
import Vue from 'vue';
import { VueAxios } from './axios';

// 创建 axios 实例
const service = axios.create({
  baseURL: '/api', // api base_url
  timeout: 3 * 60 * 1000 // 请求超时时间
})

const err = (error) => {
  if (error.message.includes('timeout')) {
    return message.error('请求服务超时:输出超时')
  }
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)

    if (error.response.status === 403) {
      console.log('服务器403啦，要重新登录！')
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 500) {
      if (data.message.length > 0) {
        message.error(data.message)
      }
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
}, err)

/**
 * response interceptor
 * 所有请求统一返回
 */
service.interceptors.response.use((response) => {
  console.log("vue===")
  if (response.request.responseType === 'blob') {
    return response
  }
  const resData = response.data
  const code = response.data.code
  if (!store.state.app.hasError) {
    if (code === 1011006 || code === 1011007 || code === 1011008 || code === 1011009) {
      Modal.error({
        title: '提示：',
        content: resData.message,
        okText: '重新登录',
        onOk: () => {
          Vue.ls.remove(ACCESS_TOKEN)
          store.dispatch('SetHasError', false)
          window.location.reload()
        }
      })
      store.dispatch('SetHasError', true)
    }
    if (code === 1013002 || code === 1016002 || code === 1015002) {
      message.error(response.data.message)
      return resData
    }
  }
  if (code !== 200) {
    if (response.data.message && router.currentRoute.path == '/user/loginAdmin' && router.currentRoute.path.indexOf('/user/loginAdmin') == -1 && router.currentRoute.path.indexOf('/userWx/login') == -1) {
      message.error(response.data.message)
    }
    return resData
  }

  return resData
}, err)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
};

