<template>
  <div>
    <!-- <nav-bar /> -->
    <route-view />
    <!-- <van-tabbar @change="onChange" v-model="active">
      <template v-for="(tab, index) in tabbar">
        <van-tabbar-item :name="tab.name" v-if="isAuth(tab.title)" :key="index" :icon="tab.icon">{{
          tab.title
        }}</van-tabbar-item>
      </template>
    </van-tabbar> -->
  </div>
</template>
<script>
import { NavBar } from '@/components'
import { mapGetters } from 'vuex'
import RouteView from './RouteView.vue'
export default {
  components: { RouteView, NavBar },
  data() {
    return {
      visible: false,
      active: 'home',
      tabActive: 'home',
      tabbar: [
        {
          title: '首页',
          icon: 'home-o',
          name: 'home',
          route: {
            name: 'mobileHome',
            query: {
              name: 'home',
            },
          },
        },
        {
          title: '收文登记',
          icon: 'records',
          name: 'billRegister',
          route: {
            name: 'mobileBillRegister',
            query: {
              name: 'billRegister',
            },
          },
        },
        {
          title: '快速发文',
          icon: 'description',
          name: 'description',
          route: {
            name: 'mobileBillSend',
            query: {
              name: 'description',
            },
          },
        },
        {
          title: '我的',
          icon: 'friends-o',
          name: 'my',
          route: {
            name: 'MobileMy',
            query: {
              name: 'my',
            },
          },
        },
      ],
      commissionersTitles: ['收文登记', '快速发文'],
    }
  },
  computed: {
    ...mapGetters(['supers', 'commons', 'commissioners', 'auth_code']),
  },
  watch: {
    '$route.query': {
      handler(val) {
        const { name } = val
        if (name) {
          this.active = name
          this.tabActive = name
        }
      },
      immediate: true,
    },
  },
  methods: {
    onChange(evt) {
      if (this.tabActive === evt) return
      this.tabActive = evt
      const { route } = this.tabbar.filter((item) => item.name == evt)[0]
      if (route) this.$router.push(route)
    },
    isAuth(title) {
      if (this.commissionersTitles.includes(title)) {
        return [...this.supers, ...this.commissioners].includes(this.auth_code)
      }
      return true
    },
  },
}
</script>
<style lang="less" scoped>
</style>
