import Vue from 'vue';
import router from './router';
import store from './store';

import '@/components/NProgress/nprogress.less'; // progress bar custom style
import { nameArray } from '@/config/mobileTerminal.router.conf'; // 移动端没做动态路由
import { ACCESS_TOKEN, ALL_APPS_MENU } from '@/store/mutation-types';
import { DEVICE_TYPE } from '@/utils/device';
import { domTitle, setDocumentTitle } from '@/utils/domUtil';
import NProgress from 'nprogress'; // progress bar

import { Modal } from 'ant-design-vue'; // NProgress Configuration
import { handleMobleDocTitle } from './utils/util';
NProgress.configure({ showSpinner: false })
const whiteList = ['login', 'register', 'registerResult', 'loginAdmin', ...nameArray(),'editorCeshi'] // no redirect whitelist
// 无默认首页的情况
const defaultRoutePath = '/fileStreamHome'

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${domTitle}`))
  if(store.state.app.device==DEVICE_TYPE.MOBILE){
    handleMobleDocTitle(to)
  }

  if (Vue.ls.get(ACCESS_TOKEN)) {
    /* has token */
    if (to.path === '/user/login'||to.path === '/userWx/login') {
      next({ path: defaultRoutePath })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        store
          .dispatch('GetInfo')
          .then(res => {
            if (res.menus.length < 1) {
              Modal.error({
                title: '提示：',
                content: '无菜单权限，请联系管理员',
                okText: '确定',
                onOk: () => {
                  store.dispatch('Logout').then(() => {
                    window.location.reload()
                  })
                }
              })
              return
            }
            // eslint-disable-next-line camelcase
            const all_app_menu = Vue.ls.get(ALL_APPS_MENU)
            let antDesignmenus
            // eslint-disable-next-line camelcase
            if (all_app_menu == null) {
              const applocation = []
              res.apps.forEach(item => {
                const apps = { 'code': '', 'name': '', 'active': '', 'menu': '' }
                if (item.active) {
                  apps.code = item.code
                  apps.name = item.name
                  apps.active = item.active
                  apps.menu = res.menus
                  antDesignmenus = res.menus
                } else {
                  apps.code = item.code
                  apps.name = item.name
                  apps.active = item.active
                  apps.menu = ''
                }
                applocation.push(apps)
              })
              Vue.ls.set(ALL_APPS_MENU, applocation, 7 * 24 * 60 * 60 * 1000)
              console.log('applocation===',applocation)
              next({ path: '/fileStreamHome' })
              // 延迟 1 秒显示欢迎信息
              // setTimeout(() => {
              //   notification.success({
              //     message: '欢迎',
              //     description: `${timeFix()}，欢迎回来`
              //   })
              // }, 1000)
            } else {
              antDesignmenus = Vue.ls.get(ALL_APPS_MENU)[0].menu
            }
            store.dispatch('GenerateRoutes', { antDesignmenus }).then(() => {
              // 动态添加可访问路由表
              router.addRoutes(store.getters.addRouters)
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
              let path = ''
              if (to.path.includes('mobile')) {
                path = to.path
              } else {
                path = from.query.redirect || to.path
              }
              const redirect = decodeURIComponent(path)
              if (to.path === redirect) {
                next({ path: redirect })
                // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                next({ ...to, replace: true })
              } else {
                // 跳转到目的路由
                next({ path: redirect })
              }
            })
          })
          .catch(() => {
            store.dispatch('Logout').then(() => {
              next({ path: '/userWx/login', query: { redirect: to.fullPath } })
            })
          })
      } else {
        next()
      }
    }
  } else {
    if (whiteList.includes(to.name)) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next({ path: '/userWx/login', query: { redirect: encodeURIComponent(to.fullPath) } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
