<template>
  <div
    v-infinite-scroll="handleInfiniteOnLoad"
    class="list"
    :infinite-scroll-disabled="busy"
    :infinite-scroll-distance="10"
    :style="{ maxHeight: maxHeight }"
  >
    <slot>
      <a-empty />
    </slot>
  </div>
</template>
<script>
import infiniteScroll from 'vue-infinite-scroll'
export default {
  directives: { infiniteScroll },
  props: {
    isFinish: {
      type: Boolean,
      default: false
    },
    msgListLength: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: String,
      default: '380px'
    }
  },
  data() {
    return {
      busy: false
    }
  },
  methods: {
    handleInfiniteOnLoad() {
      console.log('handleInfiniteOnLoad')
      this.$emit('load')
    }
  }
}
</script>
<style lang="less" scoped>
.list {
  max-height: 380px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: #1890ff !important;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    // background: rgba(0, 0, 0, 0.1);
  }
  .finish {
    text-align: center;
    padding: 10px;
  }
}
</style>
