/*
 * author kcz
 * date 2019-11-20
 */
import KFormItem from './index.vue'

KFormItem.install = function(Vue) {
  Vue.component(KFormItem.name, KFormItem)
}

export default KFormItem
