import Vue from 'vue';
// 引入写好的icon组件
import icon from '@/components/IconSelector/icon.vue';
// 注册到全局组件
Vue.component('svgIcon', icon)

const requireContext = require.context('../icons/svg', false, /\.svg$/)

// 函数 全部导入
const importAll = r => r.keys().map(r)
importAll(requireContext)