<template>
  <div class="loading_wrapper" v-show="isShowLoading" @click="isShowLoading = false">
    <div class="content">
      <a-spin :tip="title"> </a-spin>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loading',
  data() {
    return {
      title: 'loading...',
      isShowLoading: false,
    }
  },
  methods: {
    show(title) {
      this.isShowLoading = true
      if (title) {
        this.title = title
      }
    },
    hide() {
      this.isShowLoading = false
    },
  },
}
</script>
<style lang="less" scoped>
.loading_wrapper {
  /deep/ .ant-spin-dot-item {
    background-color: #fff;
  }
  /deep/ .ant-spin-text {
    color: #fff;
    font-size: 22;
  }
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 0.18rem;
  color: #fff;
  z-index: 9999;
  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    font-size: 0.28rem;
    // color: rgb(83, 83, 196);
    color: #fff;
  }
}
</style>
