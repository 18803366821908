const bill = {
  namespaced: true,
  state: {
    registration: false,
    postMsg: false,
    isShowGuide: true
  },
  mutations: {
    setRegistration: (state, data) => {
      state.registration = data
    },
    setIsShowGuide(state, data) {
      state.isShowGuide = data
    },
    setPostMsg(state, data) {
      state.postMsg = data
    }
  }
}

export default bill
