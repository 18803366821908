<template>
  <listContainer :isFinish="isFinish" :isLoading="isLoading" :msgListLength="msgListLength">
    <msgListComponent
      ref="msgListComponentPup"
      @close="close"
      @pageConfig="pageConfig"
      :config="config"
      :isUnRead="isUnRead"
      :noticeType="noticeType"></msgListComponent>
  </listContainer>
</template>
<script>
import listContainer from './list'
import msgListComponent from './msgListComponent.vue'
import { pupDialogConfig } from './config'
export default {
  components: {
    listContainer,
    msgListComponent
  },
  props: {
    noticeType: {
      type: String,
      default: ''
    },
    isUnRead: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isUnRead: {
      handler() {
        this.pageNo = 1
      }
    }
  },
  computed: {
    config() {
      const noticeType = this.noticeType
      return pupDialogConfig.find(j => j.key === noticeType)
    },
    isFinish() {
      return this.pageNo >= this.totalPage
    }
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      totalPage: 0,
      isLoading: false,
      msgListLength: 0
    }
  },
  methods: {
    pageConfig(evt) {
      if (evt.totalPage === 0 || evt.totalPage) this.totalPage = evt.totalPage
      this.isLoading = evt.isLoading
      this.msgListLength = evt.msgListLength
    },
    refresh() {
      this.$refs.msgListComponentPup && this.$refs.msgListComponentPup.refresh()
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
}
.msgList {
  .item {
    padding: 20px 16px 18px 5px;
    cursor: pointer;
    &:hover {
      background-color: rgba(250, 252, 255, 1);
    }
    .msgContent {
      width: 100%;
      justify-content: flex-start;
      .icons {
        width: 22px;
        height: 22px;
        font-size: 0;
        margin-right: 9px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .msgDetail {
          line-height: 1;
          flex: 1;
          .top {
            justify-content: space-between;
          }
          .msgTitle {
            color: #636363;
            &.msgConnectStatus {
              color: rgba(141, 189, 254, 1);
              margin-top: 10px;
            }
          }
          .msgStatus {
            color: #B7B7B7;
            font-size: 12px;
            &.isRead {
              color: rgba(118, 177, 254, 1);
            }
          }
        }
    }
  }
}
</style>
