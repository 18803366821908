<template>
  <a-drawer
    width="100%"
    title=""
    :placement="placement"
    :closable="false"
    :visible="visible"
    :destroyOnClose="destroyOnClose"
    :get-container="false"
    :wrap-style="{ position: 'absolute', zIndex: 1 }"
  >
    <div class="full-model-body">
      <a-page-header
        style="border-bottom: 1px solid rgb(235, 237, 240)"
        :title="title"
        :sub-title="subTitle"
        @back="handleCancel"
      >
        <template slot="extra">
          <slot name="up-extra"></slot>
        </template>
        <slot name="up-desc"></slot>
        <template slot="footer">
          <slot name="up-footer"></slot>
        </template>
      </a-page-header>

      <div class="full-model-content">
        <slot/>
      </div>
    </div>
  </a-drawer>
</template>

<script>
export default {
  name: 'FullModelView',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'right'
    },
    destroyOnClose: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style>
.ant-drawer-body{
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.full-model-body{
  width: 100%;
  height: 100%;
  position: relative;
}
.full-model-content{
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}
</style>
