import { axios } from '@/utils/request'
/**
 * 分页查询
 * @param {*} data
 * @returns
 */
export const noticePage = data => axios({
  url: '/document/notice/page',
  method: 'post',
  data
})
/**
 * 获取未读消息数
 * @param {*} params
 * @returns
 */
export const unReadNums = () => axios({
  url: '/document/notice/unRead',
  method: 'get'
})
/**
 * 一键已读
 * @param {*} data
 * @returns
 */
export const noticeFlush = () => axios({
  url: '/document/notice/flush',
  method: 'get'
})
/**
 * 点击跳转更新状态为已读
 * @param {*} id
 * @returns
 */
export const noticeUpdate = id => axios({
  url: `/document/notice/update/${id}`,
  method: 'get'
})

/**
 * 更新状态为已读
 * @param {*} id
 * @returns
 */
 export const noticeRefreshUpdate = id => axios({
  url: `/document/notice/refresh/${id}`,
  method: 'get'
})

/**
 * 点击跳转更新状态为已读
 * @param {*} id
 * @returns
 */
 export const noticeclearByIds = data => axios({
  url: `/document/notice/clear`,
  method: 'post',
  data
})
