<template>
    <div class="user-wrapper">
        <div class="content-box">
            <template v-if="!onlyExit">
                <div class="exitLoginContainer" id="exitLoginContainer">
                    <a-popover destroyTooltipOnHide placement="bottomRight" v-model="popoverVisible" trigger="click">
                        <template #content>
                            <msgPop @close="hidePopover"></msgPop>
                        </template>
                        <div class="msgIcon">
                            <a-badge :count="formatUnReadNums(unReadNum.unReadNums)">
                                <img src="~@/assets/img/msgIcon.png" alt="" />
                            </a-badge>
                        </div>
                    </a-popover>
                    <!-- <span class="userName">
                                <OpenData type="userName" :openid="userId"></OpenData>
                              </span>
                          <a class="exitLogin flex flex-ai-c" href="javascript:;" @click="handleLogout">
                            <img style="width: 13px; height: 13px" src="~@/assets/img/logout.png" alt="" />
                            <span class="exitBtn">退出登录</span>
                          </a>-->
                    <a-dropdown>
                        <span class="action ant-dropdown-link user-dropdown-menu flex">
                            <!-- <a-avatar class="avatar" size="small" :src="avatar" /> -->
                            <template v-if="userId">
                                <OpenData type="userName" :openid="userId"></OpenData>
                            </template>
                            <span v-else>{{ nickname || userInfo.name || '/' }}</span>
                        </span>
                        <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
                            <a-menu-item key="4" v-if="mode === 'sidemenu' && false">
                                <a @click="appToggled()">
                                    <a-icon type="swap" />
                                    <span>切换应用</span>
                                </a>
                            </a-menu-item>
                            <a-menu-item key="1">
                                <router-link :to="{ name: 'settings' }">
                                    <a-icon type="user" />
                                    <span>个人中心</span>
                                </router-link>
                            </a-menu-item>
                            <a-menu-divider />
                            <a-menu-item key="3">
                                <a href="javascript:;" @click="handleLogout">
                                    <a-icon type="logout" />
                                    <span>退出登录</span>
                                </a>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                    <a-icon
                        type="question-circle"
                        @click="$refs.help.showDrawer()"
                        style="margin-left: 10px; font-size: 18px"
                    />
                </div>
            </template>
            <template v-else>
                <span class="action" @click="toggleFullscreen">
                    <a-icon type="fullscreen-exit" v-if="isFullscreen" />
                    <a-icon type="fullscreen" v-else />
                </span>
                <notice-icon class="action" />
                <a-dropdown>
                    <span class="action ant-dropdown-link user-dropdown-menu">
                        <a-avatar class="avatar" size="small" :src="avatar" />
                        <span>{{ nickname }}</span>
                    </span>
                    <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
                        <a-menu-item key="4" v-if="mode === 'sidemenu' && false">
                            <a @click="appToggled()">
                                <a-icon type="swap" />
                                <span>切换应用</span>
                            </a>
                        </a-menu-item>
                        <!-- <a-menu-item key="0">
              <router-link :to="{ name: 'center' }">
                <a-icon type="user" />
                <span>个人中心</span>
              </router-link>
            </a-menu-item> -->
                        <a-menu-item key="1">
                            <router-link :to="{ name: 'settings' }">
                                <a-icon type="user" />
                                <span>个人中心</span>
                            </router-link>
                        </a-menu-item>
                        <a-menu-divider />
                        <a-menu-item key="3">
                            <a href="javascript:;" @click="handleLogout">
                                <a-icon type="logout" />
                                <span>退出登录</span>
                            </a>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </template>
        </div>
        <a-modal
            title="切换应用"
            :visible="visible"
            :footer="null"
            :confirm-loading="confirmLoading"
            @cancel="handleCancel"
        >
            <a-form :form="form1">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="选择应用">
                    <a-menu
                        mode="inline"
                        :default-selected-keys="this.defApp"
                        style="border-bottom: 0px; lineheight: 55px"
                    >
                        <a-menu-item
                            v-for="item in userInfo.apps"
                            :key="item.code"
                            style="top: 0px"
                            @click="switchApp(item.code)"
                        >
                            {{ item.name }}
                        </a-menu-item>
                    </a-menu>
                </a-form-item>
            </a-form>
        </a-modal>

        <!-- 帮助说明 -->
        <Help ref="help" />
    </div>
</template>

<script>
import NoticeIcon from '@/components/NoticeIcon'
import { ALL_APPS_MENU } from '@/store/mutation-types'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { message } from 'ant-design-vue/es'
import screenfull from 'screenfull'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Help from './Help.vue'
import msgPop from './msgPop.vue'

export default {
    name: 'UserMenu',
    components: {
        NoticeIcon,
        screenfull,
        msgPop,
        OpenData,
        Help,
    },
    props: {
        mode: {
            type: String,
            // sidemenu, topmenu
            default: 'sidemenu',
        },
        onlyExit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            visible: false,
            popoverVisible: false,
            confirmLoading: false,
            form1: this.$form.createForm(this),
            defApp: [],
            isFullscreen: false,
            userId: '',
        }
    },
    watch: {
        popoverVisible: {
            handler() {
                this.$store.dispatch('msg/unReadNums')
            },
        },
    },

    mounted() {
        if (Vue.ls.get('WX_data_set')) {
            this.userId = Vue.ls.get('WX_data_set').userId
        }
        this.$store.dispatch('msg/unReadNums')
    },

    computed: {
        ...mapGetters(['nickname', 'avatar', 'userInfo']),
        unReadNum() {
            return this.$store.state.msg.unReadNum
        },
    },
    methods: {
        ...mapActions(['Logout', 'MenuChange']),
        hidePopover() {
            this.popoverVisible = false
        },

        formatUnReadNums(num) {
            if (num > 99) return '...'
            return num
        },

        handleLogout() {
            this.$confirm({
                title: '提示',
                content: '真的要注销登录吗 ?',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    let fullPath = ''
                    const query = { ...this.$route.query }
                    const arr = ['appid', 'auth_code', 'state']
                    arr.forEach((a) => {
                        if (a in query) {
                            delete query[a]
                        }
                    })
                    const queryInfo = this.formatObj(query)
                    if (this.$route.name === 'Console') {
                        fullPath = '/fileStreamHome'
                    } else {
                        fullPath = `${this.$route.path}${queryInfo ? '?' : ''}${queryInfo}`
                    }
                    return this.Logout({})
                        .then(() => {
                            setTimeout(() => {
                                // window.open('/wxLogin.html','_self')
                                // window.location.reload('')
                                console.log(this.$route)
                                this.$router.push({
                                    name: 'login',
                                    query: {
                                        redirect: encodeURIComponent(fullPath),
                                    },
                                })
                            }, 16)
                        })
                        .catch((err) => {
                            this.$message.error({
                                title: '错误',
                                description: err.message,
                            })
                        })
                },
                onCancel() {},
            })
        },
        formatObj(obj) {
            let str = ''
            for (const key in obj) {
                if (Object.hasOwnProperty.call(obj, key)) {
                    const element = obj[key]
                    str += `&${key}=${element}`
                }
            }
            return str.substring(1)
        },

        /**
         * 打开切换应用框
         */
        appToggled() {
            this.visible = true
            this.defApp.push(Vue.ls.get(ALL_APPS_MENU)[0].code)
        },

        switchApp(appCode) {
            this.visible = false
            this.defApp = []
            const applicationData = this.userInfo.apps.filter((item) => item.code === appCode)
            const hideMessage = message.loading('正在切换应用！', 0)
            this.MenuChange(applicationData[0])
                .then((res) => {
                    hideMessage()
                    // eslint-disable-next-line handle-callback-err
                })
                .catch((err) => {
                    message.error('应用切换异常')
                })
        },
        handleCancel() {
            this.form1.resetFields()
            this.visible = false
        },
        /* 全屏切换 */
        toggleFullscreen() {
            if (!screenfull.isEnabled) {
                message.error('您的浏览器不支持全屏模式')
                return
            }
            screenfull.toggle()
            if (screenfull.isFullscreen) {
                this.isFullscreen = false
            } else {
                this.isFullscreen = true
            }
        },
    },
}
</script>

<style lang="less" scoped>
.appRedio {
    border: 1px solid #91d5ff;
    padding: 10px 20px;
    background: #e6f7ff;
    border-radius: 2px;
    margin-bottom: 10px;
    color: #91d5ff;
    /*display: inline;
    margin-bottom:10px;*/
}

.content-box {
    height: 100%;
}

.exitLoginContainer {
    // padding-top: 3px;
    height: 100%;
    display: flex;
    align-items: center;

    .msgIcon {
        font-size: 0;
        position: relative;
        width: 20px;
        line-height: 1;
        height: 20px;
        cursor: pointer;

        .msgNumber {
            position: absolute;
            top: 30%;
            right: -5px;
            transform: translate(40%, -60%);
            background: linear-gradient(0deg, #ff1d2d, #ff7777);
            border-radius: 10px;
            font-size: 7px;
            color: #ffffff;
            height: 18px;
            line-height: 18px;
            min-width: 18px;
            text-align: center;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .userName {
        margin-right: 10px;
        color: rgba(0, 0, 0, 0.65);
        margin-left: 20px;
    }

    .user-dropdown-menu {
        // margin-left: 20px;
    }
}

.exitLogin {
    padding-right: 12px;
    line-height: 0.8;

    i {
        color: rgba(0, 0, 0, 0.65);
    }

    .exitBtn {
        margin-left: 3px;
        color: rgba(0, 0, 0, 0.65);
    }
}
</style>
