<template>
    <div class="msgPop">
        <a-tabs default-active-key="1">
            <a-tab-pane key="1">
                <template #tab>
                    <div class="tabContainer">
                        审批通知
                        <div v-if="unReadNum.hasUnreadChecking" class="dot"></div>
                    </div>
                </template>
                <msgList ref="hasUnreadChecking" @close="close" :isUnRead="isUnRead" :noticeType="'approval'"></msgList>
            </a-tab-pane>
            <a-tab-pane key="2">
                <template #tab>
                    <div class="tabContainer">
                        接收通知
                        <div v-if="unReadNum.hasUnreadReceive" class="dot"></div>
                    </div>
                </template>
                <msgList ref="hasUnreadReceive" @close="close" :isUnRead="isUnRead" :noticeType="'accept'"></msgList>
            </a-tab-pane>
            <a-tab-pane key="3">
                <template #tab>
                    <div class="tabContainer">
                        抄送通知
                        <div v-if="unReadNum.hasUnreadSending" class="dot"></div>
                    </div>
                </template>
                <msgList ref="hasUnreadSending" @close="close" :isUnRead="isUnRead" :noticeType="'send'"></msgList>
            </a-tab-pane>
            <template slot="tabBarExtraContent">
                <a-select class="tabBarExtraContent" :default-value="1" @change="handleChange">
                    <a-select-option :value="1"> 全部消息 </a-select-option>
                    <a-select-option :value="2"> 未读消息 </a-select-option>
                </a-select>
            </template>
        </a-tabs>
        <div class="footer flex">
            <div class="flex">
                <div class="icons">
                    <img v-if="isClear" src="~@/assets/img/selectedActive.png" alt="" />
                    <img v-else src="~@/assets/img/selected.png" alt="" />
                </div>
                <div class="clear" @click="clearUnRead">全部标记为已读</div>
            </div>
            <div class="histroyMsg" @click="showHistroy">历史消息</div>
        </div>
        <dialogMsg @close="close" ref="dialogMsg"></dialogMsg>
    </div>
</template>
<script>
import dialogMsg from './dialogMsg.vue'
import msgList from './msgList.vue'
export default {
    components: {
        msgList,
        dialogMsg,
    },
    data() {
        return {
            isClear: false,
            isUnRead: false,
        }
    },
    computed: {
        unReadNum() {
            return this.$store.state.msg.unReadNum
        },
    },
    methods: {
        handleChange(evt) {
            this.isUnRead = evt === 2
        },
        close() {
            this.$emit('close')
            this.isClear = false
        },
        clearUnRead() {
            this.isClear = true
            this.$store.dispatch('msg/noticeFlush').then((res) => {
                if (res.code === 200) {
                    this.$message.success(res.message)
                    this.$refs.hasUnreadChecking && this.$refs.hasUnreadChecking.refresh()
                    this.$refs.hasUnreadReceive && this.$refs.hasUnreadReceive.refresh()
                    this.$refs.hasUnreadSending && this.$refs.hasUnreadSending.refresh()
                }
            })
        },
        showHistroy() {
            this.$refs.dialogMsg.open()
        },
    },
}
</script>
<style lang="less" scoped>
.msgPop {
    width: 470px;
    position: relative;
    .msgSelect {
        position: absolute;
        top: 5px;
        right: 10px;
        z-index: 1;
        /deep/ .ant-select-selection {
            border: none;
            box-shadow: none;
        }
    }
    .tabContainer {
        position: relative;
        .dot {
            position: absolute;
            top: -6px;
            right: -6px;
            width: 6px;
            height: 6px;
            background: #f70a15;
            border-radius: 50%;
        }
    }
    .footer {
        height: 40px;
        border-top: 1px solid rgba(243, 246, 250, 1);
        justify-content: space-between;
        color: #8a8a8a;
        font-size: 16px;
        .icons {
            width: 20px;
            height: 20px;
            font-size: 0;
            margin-right: 9px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .clear {
            cursor: pointer;
        }
        .histroyMsg {
            font-size: 16px;
            cursor: pointer;
            color: #8a8a8a;
        }
    }
}
.tabBarExtraContent {
    width: 100px;
    position: relative;
    top: 4px;
}
</style>
