import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'

const setStatus = (key, value, commit, state) => {
  const stateCopy = { ...state.initDictionaryStatus }
  stateCopy[key] = value
  commit('setInitDictionaryStatus', stateCopy)
}
export default {
  namespaced: true,
  state: {
    flow_secrecy_degree: [],
    flow_urgency_degree: [],
    flow_approval_status: [],
    flow_file_type: [],
    initDictionaryStatus: {
      secrecy: 0,
      urgency: 0,
      approval: 0,
      file: 0
    },
    addBillMess: {}
  },
  mutations: {
    setFlowSecrecyDegree(state, data) {
      state.flow_secrecy_degree = data
    },
    setInitDictionaryStatus(state, data) {
      state.initDictionaryStatus = data
    },
    setFlowUrgencyDegree(state, data) {
      state.flow_urgency_degree = data
    },
    setFlowApprovalStatus(state, data) {
      state.flow_approval_status = data
    },
    cacheBillMess(state, data) {
      state.addBillMess = data
    },
    setFlowFileDegree(state, data) {
      state.flow_file_type = data
    }
  },
  actions: {
    FlowSecrecyDegree({ commit, state }) {
      if (state.initDictionaryStatus.secrecy) return
      setStatus('secrecy', 1, commit, state)
      sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
        commit('setFlowSecrecyDegree', res.data)
        setStatus('secrecy', 0, commit, state)
      })
    },
    FlowUrgencyDegree({ commit, state }) {
      if (state.initDictionaryStatus.urgency) return
      setStatus('urgency', 1, commit, state)
      sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
        commit('setFlowUrgencyDegree', res.data)
        setStatus('urgency', 0, commit, state)
      })
    },
    FlowApprovalStatus({ commit, state }) {
      if (state.initDictionaryStatus.approval) return
      setStatus('approval', 1, commit, state)
      sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
        commit('setFlowApprovalStatus', res.data)
        setStatus('approval', 0, commit, state)
      })
    },
    FlowFileDegree({ commit, state }) {
      if (state.initDictionaryStatus.file) return
      setStatus('file', 1, commit, state)
      sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
        commit('setFlowFileDegree', res.data)
        setStatus('file', 0, commit, state)
      })
    }
  }
}
