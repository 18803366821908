<template>
  <div class="dialogMsgList position-re">
    <div class="select-btns" v-if="isClearMsg">
      <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="selectAll"> 当页全选 </a-checkbox>
      <span style="color: #3b9afa" @click="clearMsgInfoByIds"> 清除 </span>
    </div>
    <div class="messListContainer">
      <msgListComponent
        @close="close"
        @pageConfig="pageConfig"
        @changeMsgselectStatus="changeMsgselectStatus"
        ref="msgListComponent"
        :config="config"
        :isUnRead="isUnRead"
        :noticeType="noticeType"
        :isClearMsg="isClearMsg"
      />
    </div>
    <div class="footer position-ab flex width100">
      <a-pagination
        @change="handleChange"
        v-model="current"
        show-size-changer
        :show-total="() => `共 ${article} 条`"
        :total="article"
        @showSizeChange="onShowSizeChange"
        show-less-items
      />
    </div>
  </div>
</template>
<script>
import { Pagination } from 'ant-design-vue'
import { dialogMsgConfig } from './config'
import msgListComponent from './msgListComponent.vue'
export default {
  props: {
    noticeType: {
      type: String,
      default: '',
    },
    isUnRead: {
      type: Boolean,
      default: false,
    },
    // 是否展示清除按钮
    isClearMsg: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isUnRead: {
      handler() {
        this.pageNo = 1
      },
    },
  },
  computed: {
    config() {
      const noticeType = this.noticeType
      return dialogMsgConfig.find((j) => j.key === noticeType)
    },
  },
  components: {
    msgListComponent,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      current: 1,
      article: 10,
      indeterminate: false,
      checkAll: false,
      checkedMsgList: [],
    }
  },
  methods: {
    pageConfig(evt) {
      if (evt.totalRows === 0 || evt.totalRows) this.article = evt.totalRows
      this.$emit('pageConfig', evt)
    },
    close() {
      this.$emit('close')
    },
    handleChange(evt) {
      this.$refs.msgListComponent.changePage({ pageNo: evt })
    },
    onShowSizeChange(_, pageSize) {
      this.$nextTick(() => {
        this.current = 1
        this.$refs.msgListComponent.changePage({ pageNo: 1, pageSize })
      })
    },
    // 清除选择的信息
    clearMsgInfoByIds() {
      if (this.checkedMsgList.length == 0) return
      let params = {
        idList: this.checkedMsgList,
      }
      this.$store.dispatch('msg/noticeclearByIds', params).then((res) => {
        if (res.code === 200) {
          this.$message.success('清除成功~~')
          this.$refs.msgListComponent.query()
        }
      })
    },
    // 全选
    selectAll(e) {
      this.$refs.msgListComponent.selectAll(e)
    },
    // 修改全选状态
    changeMsgselectStatus({ checkAll, indeterminate, checkedMsgList }) {
      this.checkAll = checkAll
      this.indeterminate = indeterminate
      this.checkedMsgList = checkedMsgList
    },
  },
}
</script>
<style lang="less" scoped>
.footer {
  text-align: center;
  padding: 15px;
}

.dialogMsgList {
  max-height: 550px;
  overflow: hidden;
  min-height: 402px;
  .messListContainer {
    overflow-y: auto;
    max-height: 550px;
    padding-bottom: 100px;
  }
  .messListContainer::-webkit-scrollbar {
    display: none;
  }
  .footer {
    left: 0;
    bottom: 0;
    background: #fff;
  }
  .select-btns {
    padding-bottom: 15px;
    background: #fff;
    user-select: none;
    padding-left: 5px;
  }
}
</style>
