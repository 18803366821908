<template>
  <div class="help_center">
    <a-drawer :title="title" width="520" :closable="false" :visible="visible" @close="onClose">
      <div class="top">
        <div class="topTitle">帮助中心</div>
        <div class="topImg">
          <img src="~@/assets/img/enterHelp.png" alt="">
        </div>
      </div>
      <h4 class="process">
        更新记录
      </h4>
      <listContainer @load="load" :maxHeight="`calc(100vh - 460px)`">
        <ul class="item" v-for="(i, index) in pageList" :key="index">
          <li class="itemTitle">{{ i.title }}</li>
          <li v-for="(j, jIndex) in i.contentList" :key="jIndex">
            <span class="itemContent">{{ j }}</span>
          </li>
        </ul>
      </listContainer>
      <div class="footer">
        <!-- <a-button style="margin-right: 8px" @click="onClose"> Cancel </a-button> -->
        <a-button type="primary" ghost @click="$refs.isExpiredTipDialog.open({ openFlag: 'N' })">
          联系客服
        </a-button>
      </div>
      <contactCustomerService ref="isExpiredTipDialog" />
    </a-drawer>
  </div>
</template>
<script>
import contactCustomerService from '@/views/userLoginReg/components/contactCustomerService.vue'
import listContainer from './list'
export default {
    components: {
        contactCustomerService,
        listContainer
    },
    data() {
        return {
            visible: false,
            title: '帮助',
            pageNo: 0,
            pageSize: 20,
            isFinish: false,
            pageList: []
        }
    },
    methods: {
        versionPage(data) {
            return this.$store.dispatch('msg/versionPage', data)
        },
        showDrawer() {
            this.handleVisible()
        },
        onClose() {
            this.handleVisible()
        },
        handleVisible() {
            this.visible = !this.visible
        },
        load() {
            if (this.isFinish) return
            this.pageNo++
            this.versionPage({
                pageNo: this.pageNo,
                pageSize: this.pageSize
            })
            .then(res => {
                if (res.code === 200) {
                    console.log(res.data)
                    const { rows } = res.data
                    this.pageList = [...this.pageList, ...rows]
                    this.isFinish = !rows || rows.length < this.pageSize
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
::v-deep .ant-drawer-body,
::v-deep .ant-drawer-wrapper-body {
    overflow: hidden;
}
.content {
    height: calc(100% - 100px);
    background: red;
    overflow: auto;
}
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;
}
.process {
    font-size: 14px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 8px;
}
.item {
    list-style: none outside none;
    margin-bottom: 24px;
    box-sizing: content-box;
    text-align: justify;
    overflow: hidden;
    li {
        margin-top: 8px;
        width: calc(100% - 4px);
        line-height: 22px;
        max-height: 46px;
        text-align: justify;
        overflow: hidden;
        padding: 2px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        word-wrap: break-word;
        word-break: break-all;
        &.itemTitle {
            font-size: 14px;
            font-weight: 500;
        }
        span {
            cursor: pointer;
            color: #2878ff;
            display: inline;
            border-bottom: 1px solid transparent;
        }
    }
}
.topImg {
    padding-bottom: 12px;
    border-bottom: 1px solid #e4e4e5;
    margin-top: 16px;
    img {
        width: 100%;
    }
}
</style>
