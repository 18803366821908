/*
 * @Description: 增加antd ui 组件
 * @Author: kcz
 * @Date: 2020-01-02 22:41:48
 * @LastEditors: kcz
 * @LastEditTime: 2021-05-14 14:20:45
 */

// 导入ant组件
import './core/components_use'
import * as all from './mini'

export default all.default
export const setFormDesignConfig = all.setFormDesignConfig
export const setFormBuildConfig = all.setFormBuildConfig
export const KFormDesign = all.KFormDesign
export const KFormPreview = all.KFormPreview
export const KFormBuild = all.KFormBuild
export const KFormItem = all.KFormItem
