import {
  noticeclearByIds, noticeFlush, noticePage, noticeRefreshUpdate, noticeUpdate, unReadNums
} from '@/api/modular/fileStream/msg'
import { versionPage } from '@/api/modular/fileStream/helpCenter'
const msg = {
  namespaced: true,
  state: {
    unReadNum: {},
    popoverVisible: false
  },
  mutations: {
    setUnReadNum(state, data) {
      state.unReadNum = data
    },
    setPopoverVisible(state, data) {
      state.popoverVisible = data
    }
  },
  actions: {
    query(context, data) {
      return noticePage(data)
    },
    unReadNums(context) {
      unReadNums()
      .then(res => {
        if (res.code === 200) {
          context.commit('setUnReadNum', res.data)
        }
      })
    },
    noticeUpdate(context, data) {
      return noticeUpdate(data.id)
    },
    noticeRefreshUpdate(context, data) {
      return noticeRefreshUpdate(data.id)
    },
    noticeFlush(context) {
      return noticeFlush()
      .then(res => {
        if (res.code === 200) {
          context.commit('setUnReadNum', {})
        }
        return res
      })
    },
    noticeclearByIds(context,data){
      return noticeclearByIds(data)
    },
    versionPage(_, data) {
      return versionPage(data)
    }
  }
}
export default msg
