<template>
  <a-modal ref="signModel" v-model:visible="visible" :width="600" title="电子签名" @cancel="handleClear" @ok="handleOk">
    <a-row :gutter="5">
      <a-col :span="15">
        <div style="border: 1px solid rgb(236 236 236)">
          <vue-esign
            ref="esign"
            v-model:bgColor="bgColor"
            :width="800"
            :height="400"
            :is-crop="isCrop"
            :line-width="lineWidth"
            :line-color="lineColor"
          />
        </div>
      </a-col>
      <a-col :span="9">
        <div style="height: 90px; width: auto">
          <img :src="resultImg" style="height: 90px; width: 100%; border: 1px solid rgb(236 236 236)" />
        </div>
      </a-col>
    </a-row>
    <div style="margin-top: 10px">
      <a-form>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="画笔粗细：">
              <a-input-number v-model:value="lineWidth" :min="1" :max="20" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item>
              <div style="padding-right: 50px">是否裁剪：<a-checkbox v-model:checked="isCrop"></a-checkbox></div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-button type="primary" @click="handleGenerate">预览</a-button>
      <a-button @click="handleReset" style="margin-left: 15px">清屏</a-button>
    </div>
  </a-modal>
</template>
<script>
import { message } from 'ant-design-vue'
import vueEsign from './vueEsign.vue'
export default {
  components: {
    vueEsign,
    message,
  },
  data() {
    return {
      visible: false,
      resultImg: '',
      isCrop: false,
      lineWidth: 6,
      lineColor: '#000000',
      bgColor: '',
    }
  },
  created() {},
  methods: {
    show({ signature }) {
      this.visible = true
      if (signature) {
        this.resultImg = signature
        this.$refs.esign?.reset()
      }
    },
    //清空值
    handleReset() {
      this.$refs.esign.reset()
      this.resultImg = ''
    },
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res
        })
        .catch(() => {
          message.warning('无任何签字')
        })
    },
    handleClear() {
      this.visible = false
    },
    handleOk() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.$emit('successful', res)
          this.handleClear()
        })
        .catch(() => {
          message.warning('无任何签字')
        })
    },
  },
}
</script>