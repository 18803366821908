<template>
    <div class="dialogMsg" v-if="dialogMsgVisable">
        <div class="dialogContent">
            <a-tabs default-active-key="1">
                <a-tab-pane key="1">
                    <template #tab>
                        <div class="tabContainer">
                            审批通知
                            <div v-if="unReadNum.hasUnreadChecking" class="dot"></div>
                        </div>
                    </template>
                    <msgList
                        @close="close"
                        :isClearMsg="isClearMsg"
                        :isUnRead="isUnRead"
                        :noticeType="'dialogApproval'"
                    ></msgList>
                </a-tab-pane>
                <a-tab-pane key="2">
                    <template #tab>
                        <div class="tabContainer">
                            接收通知
                            <div v-if="unReadNum.hasUnreadReceive" class="dot"></div>
                        </div>
                    </template>
                    <msgList
                        @close="close"
                        :isClearMsg="isClearMsg"
                        :isUnRead="isUnRead"
                        :noticeType="'dialogAccept'"
                    ></msgList>
                </a-tab-pane>
                <a-tab-pane key="3">
                    <template #tab>
                        <div class="tabContainer">
                            抄送通知
                            <div v-if="unReadNum.hasUnreadSending" class="dot"></div>
                        </div>
                    </template>
                    <msgList
                        @close="close"
                        :isClearMsg="isClearMsg"
                        :isUnRead="isUnRead"
                        :noticeType="'dialogSend'"
                    ></msgList>
                </a-tab-pane>
                <template slot="tabBarExtraContent">
                    <div class="msgSelect flex position-re">
                        <div class="clearMsg flex cursor-df">
                            <div @click="isClearMsg = !isClearMsg">{{ isClearMsg ? '取消清除' : '清除' }}</div>
                            <span class="line height100"></span>
                        </div>
                        <div>
                            <a-select class="tabBarExtraContent" :default-value="1" @change="handleChange">
                                <a-select-option :value="1"> 全部消息 </a-select-option>
                                <a-select-option :value="2"> 未读消息 </a-select-option>
                            </a-select>
                            <img
                                class="close-img"
                                @click="dialogMsgVisable = false"
                                src="~@/assets/img/dialogMsgClose.png"
                                alt=""
                            />
                        </div>
                    </div>
                </template>
            </a-tabs>
        </div>
    </div>
</template>
<script>
import msgList from './dialogMsgList.vue'
export default {
    components: {
        msgList,
    },
    data() {
        return {
            dialogMsgVisable: false,
            isUnRead: false,
            isClearMsg: false,
        }
    },
    computed: {
        unReadNum() {
            return this.$store.state.msg.unReadNum
        },
    },
    methods: {
        handleChange(evt) {
            this.isUnRead = evt === 2
        },
        close() {
            this.$emit('close')
        },
        open() {
            this.isClearMsg = false
            this.dialogMsgVisable = true
        },
    },
}
</script>
<style lang="less" scoped>
.dialogMsg {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3500;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    .dialogContent {
        position: absolute;
        width: 580px;
        left: 50%;
        background-color: #fff;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 13px 16px;
    }
    .msgSelect {
        // position: absolute;
        // top: 20px;
        // right: 10px;
        position: relative;
        top: 3px;
        z-index: 3000;
        /deep/ .ant-select-selection {
            border: none;
            box-shadow: none;
        }
        img {
            margin-left: 4px;
            cursor: pointer;
            position: relative;
            top: -10px;
        }
        .clearMsg {
            user-select: none;
            font-size: 14px;
            .line {
                width: 2px;
                height: 12px;
                background: #cbcbcb;
                margin-left: 11px;
            }
        }
    }
    .tabContainer {
        position: relative;
        .dot {
            position: absolute;
            top: -6px;
            right: -6px;
            width: 6px;
            height: 6px;
            background: #f70a15;
            border-radius: 50%;
        }
    }
    .tabBarExtraContent {
        width: 100px;
    }
}
</style>
