import { axios } from '@/utils/request'




/**
 * 
 * 
 */
export function wxlogin (parameter) {
  return axios({
    url: '/wx/login',
    method: 'post',
    data: parameter
  })
}




export function wx_org (parameter) {
  return axios({
    url: '/wx/corp/org',
    method: 'get',
    params: parameter
  })
}

/**
 * 同步通讯录
 * @returns
 */
export const syncBookMess = () => axios({
  url: '/wx/sync/corpInfo',
  method: 'post'
})

export function wx_user (parameter) {
  return axios({
    url: '/wx/corp/dialog',
    method: 'get',
    params: parameter
  })
}


export function wxRequest (parameter) {
  return axios({
    url: '/wx/ticket/corp',
    method: 'get',
  })
}


export function wxRequest_app (parameter) {
  return axios({
    url: '/wx/ticket/app',
    method: 'get',
  })
}


export function corp_dept (parameter) {
  return axios({
    url: '/wx/dept/user/',
    method: 'post',
    data: parameter
  })
}

export const corpDeptPage = parameter => axios({
  url: '/wx/dept/user/',
  method: 'get',
  params: parameter
})
