<template>
  <div>
    <a-modal v-model:visible="visible" centered title="" @ok="handleOk" @cancel="visible = false">
      <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      value: '1111',
      key: '',
    }
  },
  methods: {
    open(key) {
      this.key = key
      this.visible = true
    },
    handleOk() {
      this.$$emit('getvalue', {
        [this.key]: this.value,
      })
    },
  },
}
</script>

<style lang="less" scoped>
</style>