<template>
    <a-config-provider :locale="locale">
        <div id="app" class="app app1">
            <router-view class="scrollbar" />
        </div>
    </a-config-provider>
</template>

<script>
import { AppDeviceEnquire } from '@/utils/mixin'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
    mixins: [AppDeviceEnquire],
    data() {
        return {
            locale: zhCN,
        }
    },
    mounted() {
        //window.addEventListener("beforeunload",(e)=> this.beforeunloadHandler(e))
        //window.addEventListener("unload",(e)=> this.beforeunloadHandler(e))
    },
    methods: {
        /**  关闭网站 */
        beforeunloadHandler() {
            alert('sssss')
            if (e) {
                e = e || window.event
                console.log(e)
                if (e) {
                    e.returnValue = '关闭提示'
                }
                return '关闭提示'
            }
        },
    },
}
</script>
<style>
.app {
    overflow: auto;
    border: none;
}

.scrollbar {
    margin: 0 auto;
}

.app1::-webkit-scrollbar {
    /*滚动条整体样式*/
    /*高宽分别对应横竖滚动条的尺寸*/
    /* width: 8px;  */
    display: none;
}

.app1::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 6px;
    background: #aaa;
}

.app1::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 8px;
    background: #ffffff;
}

/*
  add
  */
.ant-table-thead > tr > th {
    color: white !important;
    background: #3b9afa !important;
}

.ant-table-thead > tr > th .ant-table-filter-selected.anticon {
    color: #fff !important;
}

.ant-table-thead > tr > th .anticon-filter > svg {
    margin-top: -7px !important;
}

.ant-table-pagination.ant-pagination {
    float: none !important;
    text-align: center;
}

.ant-table-column-title {
    white-space: nowrap;
}

/**  盒子容器 */
#app .ant-layout #ygh-content-box {
    width: 90%;
    /**  菜单顶部布局 */
    /* height: calc(100vh - 120px);  */
    min-height: calc(100vh - 120px);
    height: 100%;
}

#app .ant-layout #exitLoginContainer .exitLogin {
    color: #fff;
}

#app .ant-table-placeholder {
    border-bottom: 0;
}

#app .ant-layout.ant-layout-has-sider #exitLoginContainer .exitLogin {
    color: rgba(0, 0, 0, 0.65);
    padding-right: 24px;
}

#app .ant-layout.ant-layout-has-sider #ygh-content-box {
    width: 100%;
    height: calc(100vh - 120px);
    /**  菜单左侧布局 */
}
</style>
