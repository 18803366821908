<template>
  <a-modal ref="cropmodal" v-model:visible="visible" :width="700" title="头像裁剪" @cancel="handleClear" @ok="cropOk">
    <a-row :gutter="10" class="flex flex-jc-sb">
      <!-- 裁剪区 -->
      <a-col :span="17" style="height: 276px">
        <VueCropper
          ref="cropper"
          class="cropper"
          :img="option.img"
          :output-size="option.size"
          :output-type="option.outputType"
          :info="true"
          :full="option.full"
          :can-move="option.canMove"
          :can-move-box="option.canMoveBox"
          :fixed-box="option.fixedBox"
          :original="option.original"
          :auto-crop="option.autoCrop"
          :auto-crop-width="option.autoCropWidth"
          :auto-crop-height="option.autoCropHeight"
          :center-box="option.centerBox"
          :high="option.high"
          :info-true="option.infoTrue"
          :max-img-size="option.maxImgSize"
          @realTime="getCropdata"
        />
      </a-col>
      <a-col :span="7">
        <div
          class="head_portrait overflow-hd"
          style="width: 165px; height: 165px; border: 1px solid #e9e9e9; border-radius: 2px"
        >
          <img class="all100" :preview="1" :src="previewUrl" />
        </div>
        <!-- <div style="padding-top: 10px; display: flex">
          <div style="height: 100px; width: 100px; border: 1px solid #e9e9e9; border-radius: 2px">
            <img class="all100" :preview="2" :src="previewUrl" />
          </div>
          <div style="height: 60px; width: 60px; border: 1px solid #e9e9e9; margin-left: 5px; border-radius: 2px">
            <img class="all100" :preview="3" :src="previewUrl" />
          </div>
        </div> -->
      </a-col>
    </a-row>
    <div style="text-align: center; padding-top: 10px">
      <a-button @click="$refs.cropper.changeScale(1)">放大</a-button>
      <a-button @click="$refs.cropper.changeScale(-1)" style="margin-left: 15px">缩小</a-button>
      <a-button @click="$refs.cropper.rotateLeft()" style="margin-left: 15px">向左旋转</a-button>
      <a-button @click="$refs.cropper.rotateRight()" style="margin-left: 15px">向右旋转</a-button>
      <div style="padding-top: 10px">
        <a-upload
          name="file"
          :show-upload-list="false"
          :custom-request="() => {}"
          accept="image/png, image/jpeg, image/gif, image/jpg"
          @change="uploadChange"
        >
          <a-button type="primary">
            <a-icon type="upload" />
            点击上传图片
          </a-button>
        </a-upload>
      </div>
      <div style="padding-top: 10px">请上传图片文件，建议不超过2M</div>
    </div>
  </a-modal>
</template>
<script>
import { VueCropper } from 'vue-cropper'
export default {
  components: { VueCropper },
  props: {
    circle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 配置信息
      option: {
        img: '',
        size: 1,
        full: false,
        outputType: 'png',
        canMove: true,
        fixedBox: this._props.circle,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 200,
        autoCropHeight: 200,
        centerBox: false,
        high: false,
        cropData: {},
        enlarge: 1,
        mode: 'contain',
        maxImgSize: 3000,
        limitMinSize: [100, 120],
      },
      visible: false,
      previewUrl: '',
      fileName: '', // 上传文件名称
      fileType: '', // 上传图片类型
    }
  },
  created() {},
  methods: {
    getBase64(img, callback) {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    },
    cropOk() {
      this.$refs.cropper.getCropBlob((blobData) => {
        this.$emit('successful', { fileName: this.fileName, blobData: blobData, fileType: this.fileType })
        this.handleClear()
      })
    },
    // 情况当前值
    handleClear() {
      this.visible = false
      this.option.img = this.previewUrl = this.fileName = ''
    },
    // 上传文件
    uploadChange({ file }) {
      const { getBase64 } = this
      getBase64(file.originFileObj, (imageUrl) => {
        this.fileName = file.name
        this.fileType = file.type
        this.option.img = imageUrl
        console.log('file++++++++', file)
      })
    },
    show({ avatar }) {
      const { option } = this
      option.img = avatar
      this.visible = true
    },
    getCropdata() {
      this.$refs.cropper.getCropData((data) => {
        // this.previewUrl = data
        this.$set(this, 'previewUrl', data)
      })
    },
  },
}
</script>