export const dialogMsgConfig = [
  {
    index: 1,
    type: 'setDialogApproval',
    key: 'dialogApproval'
  },
  {
    index: 2,
    type: 'setDialogAccept',
    key: 'dialogAccept'
  },
  {
    index: 3,
    type: 'setDialogSend',
    key: 'dialogSend'
  }
]
export const pupDialogConfig = [
  {
    index: 1,
    type: 'setApproval',
    key: 'approval'
  },
  {
    index: 2,
    type: 'setAccept',
    key: 'accept'
  },
  {
    index: 3,
    type: 'setSend',
    key: 'send'
  }
]
