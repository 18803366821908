<template>
    <transition name="showHeader">
        <div v-if="visible" class="header-animat">
            <a-layout-header
                v-if="visible"
                :class="[
                    'ant-header-side-content1',
                    fixedHeader && 'ant-header-fixedHeader',
                    sidebarOpened ? 'ant-header-side-opened' : 'ant-header-side-closed',
                ]"
            >
                <div v-if="mode === 'sidemenu'" class="header">
                    <a-menu mode="horizontal" class="side-menu1" :default-selected-keys="this.defApp">
                        <a-icon
                            v-if="device === 'mobile'"
                            class="trigger"
                            :type="collapsed ? 'menu-fold' : 'menu-unfold'"
                            @click="toggle"
                        />
                        <a-icon
                            v-else
                            class="trigger"
                            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                            @click="toggle"
                        />

                        <a-menu-item
                            v-for="item in userInfo.apps"
                            :key="item.code"
                            @click="switchApp(item.code)"
                            class="menu-item-user"
                        >
                            {{ item.name }}
                        </a-menu-item>
                        <user-menu :onlyExit="true"></user-menu>
                    </a-menu>
                </div>
                <div v-else :class="['top-nav-header-index', theme]">
                    <div class="header-index-wide">
                        <div class="header-index-left">
                            <logo class="top-nav-header" :show-title="device !== 'mobile'" />
                            <s-menu v-if="device !== 'mobile'" mode="horizontal" :menu="menus" :theme="theme" />
                            <a-icon
                                v-else
                                class="trigger"
                                :type="collapsed ? 'menu-fold' : 'menu-unfold'"
                                @click="toggle"
                            />
                        </div>
                        <user-menu :onlyExit="false" class="header-index-right"></user-menu>
                    </div>
                </div>
            </a-layout-header>
        </div>
    </transition>
</template>

<script>
import { ALL_APPS_MENU } from '@/store/mutation-types'
import { mixin } from '@/utils/mixin'
import { message } from 'ant-design-vue/es'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import SMenu from '../Menu/'
import Logo from '../tools/Logo'
import UserMenu from '../tools/UserMenu'

export default {
    name: 'GlobalHeader',
    components: {
        UserMenu,
        SMenu,
        Logo,
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
    created() {
        this.defApp.push(Vue.ls.get(ALL_APPS_MENU)[0].code)
    },
    mixins: [mixin],
    props: {
        mode: {
            type: String,
            // sidemenu, topmenu
            default: 'sidemenu',
        },
        menus: {
            type: Array,
            required: true,
        },
        theme: {
            type: String,
            required: false,
            default: 'dark',
        },
        collapsed: {
            type: Boolean,
            required: false,
            default: false,
        },
        device: {
            type: String,
            required: false,
            default: 'desktop',
        },
    },
    data() {
        return {
            visible: true,
            oldScrollTop: 0,
            defApp: [],
        }
    },
    mounted() {
        document.addEventListener('scroll', this.handleScroll, { passive: true })
    },
    methods: {
        ...mapActions(['MenuChange']),

        /**
         * 应用切换
         */
        switchApp(appCode) {
            this.defApp = []
            const applicationData = this.userInfo.apps.filter((item) => item.code === appCode)
            const hideMessage = message.loading('正在切换应用！', 0)
            this.MenuChange(applicationData[0])
                .then((res) => {
                    hideMessage()
                    // eslint-disable-next-line handle-callback-err
                })
                .catch((err) => {
                    message.error('应用切换异常')
                })
        },
        handleScroll() {
            if (!this.autoHideHeader) {
                return
            }

            const scrollTop = document.body.scrollTop + document.documentElement.scrollTop
            if (!this.ticking) {
                this.ticking = true
                requestAnimationFrame(() => {
                    if (this.oldScrollTop > scrollTop) {
                        this.visible = true
                    } else if (scrollTop > 300 && this.visible) {
                        this.visible = false
                    } else if (scrollTop < 300 && !this.visible) {
                        this.visible = true
                    }
                    this.oldScrollTop = scrollTop
                    this.ticking = false
                })
            }
        },
        toggle() {
            this.$emit('toggle')
        },
    },
    beforeDestroy() {
        document.body.removeEventListener('scroll', this.handleScroll, true)
    },
}
</script>

<style lang="less">
@import '../index.less';

.header-animat {
    position: relative;
    z-index: @ant-global-header-zindex;
}
.showHeader-enter-active {
    transition: all 0.25s ease;
}
.showHeader-leave-active {
    transition: all 0.5s ease;
}
.showHeader-enter,
.showHeader-leave-to {
    opacity: 0;
}
.ant-header-side-content1 {
    height: 55px !important;
    padding: 0 !important;
}
.side-menu1 {
    height: 55px !important;
    border-bottom: 0px;
}
.trigger {
    padding: 0 20px !important;
}
.menu-item-user {
    line-height: 55px !important;
    top: 0;
    padding: 0 10px;
}
</style>
